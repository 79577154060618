import LayoutHead from "@/components/LayoutHead";
import MobileMenuToggle from "@/modules/MobileMenuToggle";
import PrimaryNavigation from "@/modules/PrimaryNavigation";
import SiteFooter from "@/modules/SiteFooter";
import SiteHeader from "@/modules/SiteHeader";
import SiteMobileMenu from "@/modules/SiteMobileMenu";
import clsx from "clsx";

const Layout = ({
  navigation,
  pageEntry,
  children,
  pageTheme = "light",
}): JSX.Element => {
  let headerWords = [];

  // Clean up the array
  if (!!pageEntry?.headerWords) {
    pageEntry.headerWords = pageEntry.headerWords.filter(
      (item) => item.word.trim() !== "",
    );

    headerWords =
      !!pageEntry?.headerWords && pageEntry.headerWords.length > 0
        ? pageEntry.headerWords.map((w) => w.word)
        : [];
  }

  return (
    <>
      <LayoutHead />
      <div className={clsx("Layout", "flex h-screen flex-col justify-between")}>
        <div
          className={clsx("Layout__header", {
            "text-navy": pageTheme === "light",
            "text-white": pageTheme === "dark",
          })}
        >
          <SiteHeader headerWords={headerWords} showEndeavorLogo={false} />

          <PrimaryNavigation items={navigation?.primaryNavigation} />
        </div>

        <main
          id="content"
          className={clsx("Layout__main", "mb-auto", {
            "bg-navy text-white": pageTheme === "dark",
          })}
        >
          {children}
        </main>

        <div className={clsx("Layout__siteFooter")}>
          <SiteFooter
            primaryNavigation={navigation?.primaryNavigation}
            footerNavigation={navigation?.footerNavigation}
          />
        </div>
      </div>
      <SiteMobileMenu items={navigation?.primaryNavigation} />
    </>
  );
};

export default Layout;
